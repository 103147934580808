import { EquipmentGroupStId, QuickTenderScope, Selections, InteractionScope } from '../../../../types/types'
import {
  QuickTenderGroup,
  Offering,
  Interaction,
  ResiFlowInteraction,
  QuickTenderGroupWithRating,
  QuickTenderGroupWithoutRatings,
  ValueAddedServicesGroup,
  ElevatorEscalatorApfGroups,
  DoorGroups
} from './types'

interface OfferingPattern<A> {
  NoRatings: () => A
  Flexible: (groups: ElevatorEscalatorApfGroups | DoorGroups, interaction: Interaction | ResiFlowInteraction) => A
  QuickTender: (
    groups: Record<EquipmentGroupStId, QuickTenderGroup>,
    scope: QuickTenderScope,
    interactionSelections: Selections
  ) => A
  QuickTenderWithRatings: (
    groups: Record<EquipmentGroupStId, QuickTenderGroupWithRating>,
    scope: QuickTenderScope,
    interactionSelections: Selections,
    selectedInteractionRating: InteractionScope
  ) => A
  QuickTenderScopePending: (groups: Record<EquipmentGroupStId, QuickTenderGroupWithoutRatings>) => A
  ValueAddedServices: (
    groups: Record<EquipmentGroupStId, ValueAddedServicesGroup>,
    interactionSelections: Selections
  ) => A
  GSMCampaign: (groups: Record<EquipmentGroupStId, ValueAddedServicesGroup>, interactionSelections: Selections) => A // TODO: Remove as part of GSM Clean up
}

export function match<A>(offering: Offering, pattern: OfferingPattern<A>): A {
  switch (offering._tag) {
    case 'Flexible':
      return pattern.Flexible(offering.groups, offering.interaction)
    case 'QuickTender':
      return pattern.QuickTender(offering.groups, offering.scope, offering.interactionSelections)
    case 'ValueAddedServicesAddendum':
      return pattern.ValueAddedServices(offering.groups, offering.interactionSelections)
    case 'gsm_campaign': // TODO: Remove as part of GSM Clean up
      return pattern.GSMCampaign(offering.groups, offering.interactionSelections)
    case 'QuickTenderWithRatings':
      return pattern.QuickTenderWithRatings(
        offering.groups,
        offering.scope,
        offering.interactionSelections,
        offering.selectedInteractionRating
      )
    case 'QuickTenderScopePending':
      return pattern.QuickTenderScopePending(offering.groups)
    case 'NoRatings':
      return pattern.NoRatings()
  }
}
