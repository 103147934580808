import * as R from 'ramda'
import { TechnicalDependencyKey } from './types'

const join = (xs: DependencyError[], ys: DependencyError[]): DependencyError[] => R.uniq(xs.concat(ys))

export class No {
  readonly _tag: 'noError' = 'noError' as const
  concat(b: DependencyError) {
    return b
  }
}

export class And {
  readonly _tag: 'andError' = 'andError' as const
  constructor(readonly errors: DependencyError[]) {}
  concat(b: DependencyError) {
    switch (b._tag) {
      case 'noError':
        return this
      case 'andError':
        return new And(join(this.errors, b.errors))
      case 'orError':
      case 'codeError':
        return new And(join(this.errors, [b]))
    }
  }
}

function concat(this: any, b: DependencyError) {
  switch (b._tag) {
    case 'noError':
      return this
    case 'andError':
      return new And(join([this], b.errors))
    case 'orError':
    case 'codeError':
      return new And(join([this], [b]))
  }
}

export class Or {
  readonly _tag: 'orError' = 'orError' as const
  constructor(readonly left: DependencyError, readonly right: DependencyError) {}
  concat = concat
}

export class Code {
  readonly _tag: 'codeError' = 'codeError' as const
  constructor(readonly code: string) {}
  concat = concat
}

export type DependencyError = No | And | Or | Code

export const toMessage = (f: (code: string, args?: string[]) => string, err: DependencyError): string => {
  switch (err._tag) {
    case 'noError':
      return ''
    case 'andError':
      return err.errors.map(e => toMessage(f, e)).join(', ')
    case 'orError': {
      const { left, right } = err
      return f('or', [toMessage(f, left), toMessage(f, right)])
    }
    case 'codeError': {
      const { code } = err
      if (code === 'scope') {
        return f('scope')
      } else if (isTechnicalDependency(code)) {
        return f('technicalDependency', [code])
      } else {
        return f('sapCode', [f(code)])
      }
    }
  }
}

function isTechnicalDependency(code: string): code is TechnicalDependencyKey {
  return (Object as any).values(TechnicalDependencyKey).includes(code)
}
