export const isProduction = window.environment === 'prod-eu' || window.environment === 'prod-china'
export const openEnabler = (offerId: string) => window.open(`${location.origin}/api/enablers/${offerId}`, '_blank')
function propertyExists(n: any, key: string): boolean {
  return key in n && typeof n[key] !== 'undefined'
}

export function sharedWorkerEnabled(): boolean {
  const status = propertyExists(window, 'SharedWorker')
  console.info(`SharedWorker enabled: ${status}`)
  return status
}
