import {
  Module,
  OfferingQuestion,
  QuickTenderScope,
  OfferingInteractionQuestion,
  OfferSelection,
  OfferingQuestions,
  InteractionOfferingQuestions,
  GroupOfferingQuestions,
  Scope,
  OfferSelections,
  Offer
} from '../../../../types/types'
import { Offering } from './types'
import { compileDependencies } from '../dependencies/dependencies'
import { DependencyQuestion } from '../dependencies/types'

export interface GroupOfferingQuestionsByEquipmentCategory {
  elevator: OfferingQuestion[]
  escalator: OfferingQuestion[]
  door: OfferingQuestion[]
  resiflow: OfferingQuestion[]
}

export function getGroupOfferingQuestionsByOffering(
  questions: OfferingQuestions,
  offering: Offering,
  isGSMOffer: boolean | null | undefined = false
): GroupOfferingQuestionsByEquipmentCategory {
  switch (offering._tag) {
    case 'Flexible':
      return {
        elevator: questions.group.elevator,
        escalator: questions.group.escalator,
        door: questions.group.door,
        resiflow: questions.group.resiflow
      }
    case 'QuickTender':
    case 'QuickTenderWithRatings':
      switch (offering.scope) {
        case 'konexion':
          return {
            elevator: questions.group.additionalServices,
            escalator: [],
            door: [],
            resiflow: []
          }
        case 'kone_care_taker':
          return {
            elevator: questions.group.kone_care_taker,
            escalator: [],
            door: [],
            resiflow: []
          }
        // old scopes use elevator questions
        default:
          return {
            elevator: questions.group.elevator,
            escalator: [],
            door: [],
            resiflow: []
          }
      }
    case 'ValueAddedServicesAddendum':
      return {
        elevator: isGSMOffer ? questions.group.gsm_campaign : questions.group.valueAddedServices, // TODO: Remove as part of GSM Clean up
        escalator: [],
        door: [],
        resiflow: []
      }
    case 'gsm_campaign': // TODO: Remove as part of GSM Clean up
      return {
        elevator: questions.group.gsm_campaign,
        escalator: [],
        door: [],
        resiflow: []
      }
    case 'QuickTenderScopePending':
    case 'NoRatings':
      return { elevator: [], escalator: [], door: [], resiflow: [] }
  }
}

export function getInteractionOfferingQuestions(
  questions: OfferingQuestions,
  offering: Offering,
  offer: Offer
): OfferingInteractionQuestion[] {
  switch (offering._tag) {
    case 'Flexible':
      return questions.interaction.flexible
    case 'QuickTender':
    case 'QuickTenderWithRatings':
      return offering.scope === 'konexion' ? questions.interaction.additionalServices : questions.interaction.flexible
    case 'ValueAddedServicesAddendum':
      // TODO: remove GSM Campaign
      return offer.isGSMOffer ? questions.interaction.gsm_campaign : questions.interaction.valueAddedServices
    // TODO: remove GSM Campaign
    case 'gsm_campaign':
      return questions.interaction.gsm_campaign
    case 'QuickTenderScopePending':
    case 'NoRatings':
      return []
  }
}

export function getGroupOfferingQuestionsForQuickTenderScope(
  questions: GroupOfferingQuestions,
  scope: QuickTenderScope
): OfferingQuestion[] {
  switch (scope) {
    case 'konexion':
      return questions.additionalServices
    case 'kone_care_taker':
      return questions.kone_care_taker
    // Old scopes use elevators questions
    default:
      return questions.elevator
  }
}

export function getInteractionOfferingQuestionsForQuickTenderScope(
  questions: InteractionOfferingQuestions,
  scope: QuickTenderScope
): OfferingInteractionQuestion[] {
  if (scope === 'konexion') {
    return questions.additionalServices
  }
  return questions.flexible
}

export const compileInteractionOfferingQuestions = (questions: OfferSelection[]): OfferingInteractionQuestion[] =>
  (compileDependencies(questions) as OfferingInteractionQuestion[]).map(q => ({
    choices: q.choices,
    default_values: q.default_values,
    key: q.key,
    optional: q.optional,
    type: q.type,
    ui_order: q.ui_order,
    visibility: q.visibility,
    selections: q.selections,
    dependencies: q.dependencies
  }))

export function compileOfferingQuestions(offerSelections: OfferSelections): OfferingQuestions {
  return {
    group: {
      elevator: compileDependencies(offerSelections.group.elevator) as OfferingQuestion[],
      escalator: compileDependencies(offerSelections.group.escalator) as OfferingQuestion[],
      door: compileDependencies(offerSelections.group.door) as OfferingQuestion[],
      resiflow: compileDependencies(offerSelections.group.resiflow) as OfferingQuestion[],
      additionalServices: compileDependencies(offerSelections.group.additionalServices) as OfferingQuestion[],
      valueAddedServices: compileDependencies(offerSelections.group.valueAddedServices) as OfferingQuestion[],
      kone_care_taker: compileDependencies(offerSelections.group.kone_care_taker) as OfferingQuestion[],
      gsm_campaign: compileDependencies(offerSelections.group.gsm_campaign) as OfferingQuestion[] // TODO: Remove as part of GSM Clean up
    },
    interaction: {
      flexible: compileInteractionOfferingQuestions(offerSelections.interaction.flexible),
      additionalServices: compileInteractionOfferingQuestions(offerSelections.interaction.additionalServices),
      valueAddedServices: compileInteractionOfferingQuestions(offerSelections.interaction.valueAddedServices),
      gsm_campaign: compileInteractionOfferingQuestions(offerSelections.interaction.gsm_campaign)
    }
  }
}

export const getFlexibleQuestionsForModule = (moduleName: Module, questions: OfferingQuestion[]): OfferingQuestion[] =>
  questions.filter(question => question.module === moduleName)

export interface ModuleScopes {
  basics: Scope
  technical: Scope
  performance: Scope
  value_added_services: Scope
  extra_services: Scope
  resiflow: Scope
  gsm_campaign: Scope // TODO: Remove as part of GSM Clean up
  people_flow: Scope
}

// Takes the list of questions and current module scopes
// and updates each question to have a `scope` property that
// has the current scope of the question.
export function getQuestionsWithCurrentScope(
  questions: OfferingQuestion[],
  moduleScopes: ModuleScopes
): DependencyQuestion[] {
  return questions.map(q => ({ ...q, scope: moduleScopes[q.module] }))
}
